/***
 * FUNCTIONS
 ***/
// Check mobile
var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

// ScrollAnim
function scrollAnim (target) {
    $(target).animatescroll({
        scrollSpeed: 3000,
        easing: 'easeOutExpo',
        padding: 115,
    });
}

/***
 * GERAL
 ***/
$(function() {

    // menu
    var $win = $(window),
        $menu = $('header nav'),
        $menuToggle = $('header .menuToggle');

    $($menuToggle).click(function(event) {
        event.preventDefault();
        $menu.slideToggle();
        $('html').toggleClass('open-menu');
    });

    if ($win.width() <= 768) {
        $('header nav a').click(function(event) {
            $menu.slideToggle();
            $('html').toggleClass('open-menu');
        });
    }

    $win.resize(function(event) {
        $('html').removeClass('open-menu');

        if ($win.width() <= 768) {
            $($menu).css('display', 'none');
        } else {
            $($menu).css('display', 'block');
        }
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > 30) {
            $('header').addClass('_fixed');
        } else {
            $('header').removeClass('_fixed');
        }
    });

    // imagesLoaded
    $('html').imagesLoaded().always( function( instance ) {
        $('html').addClass('loaded');
    });

    // wow
    new WOW({
        offset: 300,
        delay: 0.2
    }).init();


    // owl carousel
    $('.Banner .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        autoplay        : true,
        dots            : true,
        nav             : false,
        animateOut      : 'fadeOut'
    });

    $('.Galeria .owl-carousel').owlCarousel({
        loop            : true,
        autoplay        : true,
        autoplayTimeout : 2000,
        dots            : false,
        nav             : true,
        navText         : ['<i class="icon-left"></i>','<i class="icon-right"></i>'],
        responsive:{
            0:{
                stagePadding: 50,
                items:1
            },
            482:{
                stagePadding: 50,
                items:2
            },
            768:{
                stagePadding: 100,
                items:3
            },
            1025:{
                stagePadding: 150,
                items:4
            },
            1500:{
                stagePadding: 200,
                items:4
            }
        }
    });
    $('.Depoimentos .owl-carousel').owlCarousel({
        items           : 1,
        loop            : true,
        dots            : false,
        nav             : true,
        navText         : ['<i class="icon-left"></i>','<i class="icon-right"></i>'],
        responsive:{
            0:{
                autoplay    : false,
                autoHeight  : true
            },
            768:{
                autoplay    : true,
                autoHeight  : true
            },
        }
    });

    // Galeria
    $('.galeria, ._depoimento').each(function() {
        $(this).magnificPopup({
            delegate    : 'a',
            type        : 'image',
            fixedBgPos  : false,
            gallery     : {
                enabled:true
            },
            image       : {
                titleSrc: function(item) {
                    var texto = item.el.children('.texto');
                    var p1 = texto.children('p:first').html();
                    var p2 = texto.children('p:last').html();
                    var cor = texto.data('color');
                    return '<div class="'+cor+' figcaption"><p class="small">'+p1+'</p> <h4>'+item.el.attr('title')+', '+p2+'</h4></div>';
                }
            }
        });
    });

    // Scrollspy
    $('body').scrollspy({
        target: 'header nav',
        offset: 300
    });

    // Scroll
    $('.scroll').on('click', function(event) {
        var data   = $(this).data('target');
        var href   = $(this).attr('href');
        var target = (data !== undefined) ? data : href;

        scrollAnim(target);
    });

    // Input masks
    $("input.cpf").mask("999.999.999-99",{autoclear: false});
    $("input.cnpj").mask("99.999.999/9999-99",{autoclear: false});
    $("input.data").mask("99/99/9999",{autoclear: false});
    $("input.cep").mask("99999-999",{autoclear: false});
    $("input.hora").mask("99:99",{autoclear: false});
    $("input[name=datas]").mask("99/99/99 - 99/99/99",{autoclear: true, placeholder: "0"});

    $("input.telefone").focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("99 99999-999?9",{autoclear: false});
        } else {
            element.mask("99 9999-9999?9",{autoclear: false});
        }
    }).trigger('focusout');

    autosize($('textarea'));

    $('form').attr('autocomplete', 'off');

    
    $('.form-item span').each(function() {
        var $input = $(this).children('input').attr('placeholder');
        var $textarea = $(this).children('textarea').attr('placeholder');

        if ($input) {
            $(this).append('<span class="small-text _hide">'+$input+'</span>');
        } else if ($textarea){
            $(this).append('<span class="small-text _hide">'+$textarea+'</span>');
        }

        $(this).focusin('input', function(event) {
            $(this).children('.small-text').removeClass('_hide');
            $(this).children().attr('placeholder', '');
        });

        var $child = $(this).children('input', 'textarea');
        var $childText = $(this).children('.small-text');
        $child.focusout(function(event) {
            if ( $child.val() == "00/00/00 - 00/00/00" || $child.val() == "" ) {
                $child.val("");
            }

            if( !$child.val() ) {
                $childText.addClass('_hide');
                
                if ($input) {
                    $child.attr('placeholder', $input);
                } else if ($textarea){
                    $child.attr('placeholder', $textarea);
                }
            }
        });
    });

    // get val orcamento
    /*var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1];
            }
        }
    };

    $('.Row._topo .form-item span').each(function() {
        var $inputName = $(this).children('input').attr('name');
        var $val = getUrlParameter($inputName);
        var $input = $(this).children('input').val($val);

        if( $(this).children('input').val() ) {
            $(this).children('.small-text').removeClass('_hide');
            $(this).children().attr('placeholder', '');
        }
    });*/

    $('.button-orcamento').click(function() {
        origem = $('.origem_topo').val();
        destino = $('.destino_topo').val();
        datas = $('.data_topo').val();
        qtd = $('.qtd_topo').val();

        $('.origem input').val(origem);
        $('.destino input').val(destino);
        $('.datas input').val(datas);
        $('.qtd input').val(qtd);
    });
});